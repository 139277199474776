.Avatar {
  display: inline-block;
}
.Avatar .Avatar_image {
  border-radius: 50%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.Avatar .Avatar_image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.Avatar .Avatar_initials {
  position: absolute;
  text-align: center;
  top:50%;
  left:50%;
  transform: translateY(-50%) translateX(-50%);
  color: white;
}
.Avatar .Avatar_label {
  display: inline-block;
  margin-left: .5em;
  vertical-align: middle;
}
@media (min-width: 992px) {
  .Avatar .Avatar_label {
    display: none;
  }
}
