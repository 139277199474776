.DossierList .card-columns {
  column-count: 1
}
@media (min-width: 576px) {
  .DossierList .card-columns {
    column-count: 2
  }
}
@media ( min-width: 768px ) {
  .DossierList .card-columns {
    column-count: 2
  }
}
@media (min-width: 992px) {
  .DossierList .card-columns {
    column-count: 3
  }
}
@media (min-width: 1200px) {
  .DossierList .card-columns {
    column-count: 3
  }
}
@media (min-width: 1400px) {
  .DossierList .card-columns {
    column-count: 4
  }
}
