@import url(https://use.fontawesome.com/releases/v5.11.2/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: 'Rubik',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hide {
  display: none;
}
.input-group-append .btn, .input-group-prepend .btn {
  z-index: 0
}
.custom-file-label {
  overflow: hidden;
}
